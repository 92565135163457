<template>
  <div>
    <v-card class="fill-height mb-5">
      <v-card-title>QRCode Scans</v-card-title>
      <v-card-text>
        <div class="d-flex align-center pb-5">
          <v-select
            v-model="filter.idPtKampagne"
            label="Kampagne"
            outlined
            class="channel-list-search me-3"
            dense
            hide-details
            :items="campaigns"
            item-text="displayName"
            item-value="idPtKampagne"
          ></v-select>
          <inline-date-picker v-model="filter.datumVon" label="Datum von"/>
          <inline-date-picker v-model="filter.datumBis" label="Datum bis"/>
        </div>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12" md="12">
        <CampaignLineChart :filter="filter" title="Gesamt/Unique"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <CampaignRadialChart :filter="filter" geschlecht="1" title="Männer"/>
      </v-col>

      <v-col cols="12" md="6">
        <CampaignRadialChart :filter="filter" geschlecht="2" title="Frauen"/>
      </v-col>

<!--      <v-col cols="12" md="4">
        <CampaignRadialChart :filter="filter" geschlecht="0" title="Divers"/>
      </v-col>-->
    </v-row>

    <v-row>

      <v-col cols="12" md="6">
        <CampaignDonutChart :filter="filter" groupBy="plzRegion" title="PLZ-Region"/>
      </v-col>

      <v-col cols="12" md="6">
        <CampaignDonutChart :filter="filter" groupBy="geschlecht" title="Geschlecht"/>
      </v-col>

    </v-row>


    <v-row>
      <v-col>
        <a
          :href="`/api/dashboard/?aktion=downloadCampaignStatisticsAsPDF`"
          :download="`Report.pdf`"
        >
          <v-btn small>
            <v-icon size="18">{{ icons.mdiFilePdfOutline }}</v-icon>
            &nbsp; Report
          </v-btn>
        </a>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import {onMounted, ref, getCurrentInstance} from '@vue/composition-api'
import themeConfig from '@themeConfig'
import axios from "axios";
import InlineDatePicker from "@/views/InlineDatePicker.vue";
import CampaignDonutChart from "@/views/campaignStatistics/CampaignDonutChart";
import CampaignLineChart from "@/views/campaignStatistics/CampaignLineChart";
import CampaignRadialChart from "@/views/campaignStatistics/CampaignRadialChart";
import {
  mdiFilePdfOutline
} from "@mdi/js";


export default {
  components: {
    CampaignRadialChart,
    CampaignLineChart,
    CampaignDonutChart,
    InlineDatePicker
  },
  setup() {

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const filter = ref({
      idPtKampagne : "all"
    });

    const campaigns = ref([])

    const fetchCampaigns = () => {
      axios.post('/api/ajaxServer/', {
        aktion: 'fetchCampaigns',
        addAllOption: 1,
        filter: {...filter.value, angabeQrcodeEinfuegen:1, status:"inVersendet"}
      })
        .then((response) => {
          campaigns.value = response.data.campaigns
        })
    }

    onMounted(()=>{
      fetchCampaigns();
    })

    return {
      filter,
      campaigns,
      icons: {
        mdiFilePdfOutline
      },
    }
  },
}
</script>

<style lang="scss">

</style>
