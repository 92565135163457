import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"fill-height mb-5"},[_c(VCardTitle,[_vm._v("QRCode Scans")]),_c(VCardText,[_c('div',{staticClass:"d-flex align-center pb-5"},[_c(VSelect,{staticClass:"channel-list-search me-3",attrs:{"label":"Kampagne","outlined":"","dense":"","hide-details":"","items":_vm.campaigns,"item-text":"displayName","item-value":"idPtKampagne"},model:{value:(_vm.filter.idPtKampagne),callback:function ($$v) {_vm.$set(_vm.filter, "idPtKampagne", $$v)},expression:"filter.idPtKampagne"}}),_c('inline-date-picker',{attrs:{"label":"Datum von"},model:{value:(_vm.filter.datumVon),callback:function ($$v) {_vm.$set(_vm.filter, "datumVon", $$v)},expression:"filter.datumVon"}}),_c('inline-date-picker',{attrs:{"label":"Datum bis"},model:{value:(_vm.filter.datumBis),callback:function ($$v) {_vm.$set(_vm.filter, "datumBis", $$v)},expression:"filter.datumBis"}})],1)])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('CampaignLineChart',{attrs:{"filter":_vm.filter,"title":"Gesamt/Unique"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('CampaignRadialChart',{attrs:{"filter":_vm.filter,"geschlecht":"1","title":"Männer"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('CampaignRadialChart',{attrs:{"filter":_vm.filter,"geschlecht":"2","title":"Frauen"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('CampaignDonutChart',{attrs:{"filter":_vm.filter,"groupBy":"plzRegion","title":"PLZ-Region"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('CampaignDonutChart',{attrs:{"filter":_vm.filter,"groupBy":"geschlecht","title":"Geschlecht"}})],1)],1),_c(VRow,[_c(VCol,[_c('a',{attrs:{"href":"/api/dashboard/?aktion=downloadCampaignStatisticsAsPDF","download":"Report.pdf"}},[_c(VBtn,{attrs:{"small":""}},[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(_vm._s(_vm.icons.mdiFilePdfOutline))]),_vm._v("   Report ")],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }