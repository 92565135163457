<template>
  <div>
    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card class="mb-5" v-else>
      <v-card-title>{{title}}</v-card-title>
      <v-card-text>
        <vue-apex-charts
          type="radialBar"
          height="450"
          :options="chartData.chartOptions"
          :series="chartData.series"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {onMounted, ref, getCurrentInstance,watch} from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'
import themeConfig from '@themeConfig'
import axios from "axios";
import InlineDatePicker from "@/views/InlineDatePicker.vue";
import useHelper from "@/views/useHelper";

export default {
  components: {
    VueApexCharts,
    InlineDatePicker
  },
  props:{
    title:{
      type: String,
      default : ""
    },
    filter:{
      type: Object,
      default : () => {}
    },
    geschlecht:{
      type: String,
      default : 1
    }
  },
  setup(props) {

    const {
      radialBarChartTemplate
    } = useHelper()

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const loading = ref(false);
    const chartData = ref({});
    chartData.value = JSON.parse(JSON.stringify(radialBarChartTemplate));

    const fetchChartData = () => {
      loading.value = true;
      axios.post('/api/dashboard/', {
        aktion: 'fetchCampaignRadialStatistics',
        filter: {...props.filter, geschlecht:props.geschlecht}
      })
        .then((response) => {
          let tmpData = JSON.parse(JSON.stringify(radialBarChartTemplate));
          tmpData.series = response.data.stats.series;
          tmpData.chartOptions.labels = response.data.stats.labels;
          chartData.value = tmpData;
          loading.value = false;
        })
        .catch((error) => {
          loading.value = true;
        })
    }

    onMounted(()=>{
      fetchChartData();
    })

    watch(() => props.filter, fetchChartData, { deep: true });

    return {
      loading,
      fetchChartData,
      chartData,
    }
  },
}
</script>

<style lang="scss">

</style>
